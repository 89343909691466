import React from 'react'
import { contact } from '../../portfolio'
import './Contact.css'

const Contact = () => {
  if (!contact.email) return null

  return (
    <section className='section contact center' id='contact'>
      <h2 className='section__title'>Prenez contact avec votre développeur web et web designer dans le Médoc</h2>
      <p>Pour toute demande de création ou refonte de site internet, vous pouvez nous contacter par email à tout moment. Nous répondons rapidement afin de vous accompagner dans vos projets de développement web et web design sur-mesure.</p>
      <a href={`mailto:${contact.email}`}>
        <span type='button' className='btn btn--outline'>
          Envoyer un email
        </span>
      </a>
      <p>Si vous souhaitez échanger plus en détail sur votre projet, n’hésitez pas à prendre rendez-vous avec nous. Nous proposons des entretiens personnalisés, en présentiel ou en visioconférence, selon vos préférences.</p>
      <a href={`https://booking.alfdigitalstudio.com/?fluent-booking=calendar&host=studio_alf&event=30min`}>
        <span type='button' className='btn btn--outline'>
          Prendre rendez-vous
        </span>
      </a>
    </section>
  )
}

export default Contact
