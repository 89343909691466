import React from 'react';
import { header } from "../../portfolio";
import Navbar from "../Navbar/Navbar";
import "./Header.css";
import logo from "../../img/logo/svg/LogoW.svg";

const Header = () => {
  const { homepage } = header;

  return (
    <header className="header center">
      <span>
        {homepage ? (
          <a href={homepage} className="link">
            <svg
              version="1.1"
              baseProfile="basic"
              id="Logo"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 250 250"
              width="15vw"     
              height="15vh"     
              xmlSpace="preserve"
              className="logo-svg"
            >
              <g>
                <g>
                  <path
                    className="st0"
                    d="M123.6,192.3l-8.5-27.8H65.3l-9.5,27.8H26L74.1,57.7h32.7l47.5,134.7H123.6z M90.1,87.9h-0.2l-17.2,53.5h34.3 L90.1,87.9z"
                  />
                </g>
                <g>
                  <polygon
                    className="st0"
                    points="106.9,57.7 106.9,57.7 106.9,57.7"
                  />
                  <polygon
                    className="st0"
                    points="185.2,192.3 224,192.3 224,140.7 167.2,141.2"
                  />
                  <polygon
                    className="st0"
                    points="157,112.2 224,111.6 224,86 147.9,86.5"
                  />
                </g>
              </g>
            </svg>
          </a>
        ) : (
          <img src={logo} alt="ALF Digital Studio Logo" className="logo" />
        )}
      </span>
      <Navbar />
    </header>
  );
};

export default Header;
