import React, { useEffect, useRef, useState } from 'react';
import canvasSketch from 'canvas-sketch';
import random from 'canvas-sketch-util/random';
import math from 'canvas-sketch-util/math';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import './AnimatedHero.css';

const AnimatedHero = () => {
  const canvasRef = useRef(null);
  const [strokeColor, setStrokeColor] = useState('#08ea08'); // Default color

  useEffect(() => {
    // Function to determine the stroke color based on the class of the #top element
    const updateStrokeColor = () => {
      const topElement = document.getElementById('top');
      if (topElement) {
        if (topElement.classList.contains('dark')) {
          setStrokeColor('#08ea08'); // Color for dark theme
        } else {
          setStrokeColor('#319197'); // Color for light theme
        }
      }
    };

    updateStrokeColor(); // Call the function to set the initial stroke color

    // Add a MutationObserver to detect changes in the class of the #top element
    const observer = new MutationObserver(updateStrokeColor);
    const targetNode = document.getElementById('top');
    if (targetNode) {
      observer.observe(targetNode, { attributes: true, attributeFilter: ['class'] });
    }

    return () => observer.disconnect(); // Cleanup the observer on unmount
  }, []); // Empty dependency array to run the effect only once on mount

  useEffect(() => {
    const settings = {
      dimensions: [window.innerWidth * 0.8, window.innerHeight * 0.8],
      animate: true,
      context: '2d',
    };

    const sketch = ({ context, width, height }) => {
      return ({ context, width, height, frame }) => {
        // Clear the canvas
        context.clearRect(0, 0, width, height);

        // Number of columns and rows
        const cols = 10;
        const rows = 50;
        const numCells = cols * rows;

        // Set grid size to be 80% of the canvas size
        const gridw = width * 0.8;
        const gridh = height * 0.8;

        // Calculate cell size
        const cellw = gridw / cols;
        const cellh = gridh / rows;

        // Margins to center the grid within the canvas
        const margx = (width - gridw) * 0.5;
        const margy = (height - gridh) * 0.5;

        for (let i = 0; i < numCells; i++) {
          const col = i % cols;
          const row = Math.floor(i / cols);

          // Calculate the cell position
          const x = col * cellw;
          const y = row * cellh;
          const w = cellw * 0.8;
          const h = cellh * 0.8;

          // Noise and transformations
          const n = random.noise3D(x, y, frame * 5, 0.002);
          const angle = n * Math.PI * 0.2;
          const scale = math.mapRange(n, -1, 1, 1, 30);

          context.save();

          // Translate to the grid position and apply margins
          context.translate(x + margx + cellw * 0.5, y + margy + cellh * 0.5);
          context.rotate(angle);

          // Set stroke width and color
          context.lineWidth = scale;
          context.strokeStyle = strokeColor; // Use dynamic stroke color

          // Draw the lines
          context.beginPath();
          context.moveTo(w * -0.5, 0);
          context.lineTo(w * 0.5, 0);
          context.stroke();

          context.restore();
        }
      };
    };

    let manager;
    (async () => {
      manager = await canvasSketch(sketch, { ...settings, canvas: canvasRef.current });
    })();

    return () => {
      if (manager && typeof manager.unload === 'function') {
        manager.unload();
      }
    };
  }, [strokeColor]); // Re-run when strokeColor changes

  return (
    <div className="canvas-container">
      <canvas ref={canvasRef} className="canvas-element" />
      <div className="arrow-container">
        <a href="#about" aria-label="top">
          <ArrowDownwardIcon className="arrow" />
        </a>
      </div>
    </div>
  );
};

export default AnimatedHero;
