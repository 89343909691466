import React from 'react'
import { useContext } from 'react'
import { ThemeContext } from './contexts/theme'
import Header from './components/Header/Header'
import AnimatedHero from './components/AnimatedHero/AnimatedHero'
import About from './components/About/About'
import Projects from './components/Projects/Projects'
import Skills from './components/Skills/Skills'
import ScrollToTop from './components/ScrollToTop/ScrollToTop'
import Contact from './components/Contact/Contact'
import Blogs from './components/Blogs/Blogs'
import Footer from './components/Footer/Footer'
import './App.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom' // Ajoutez Routes ici

const App = () => {
  const [{ themeName }] = useContext(ThemeContext)

  return (
    <Router>
      <div id='top' className={`${themeName} app`}>
        <Header />
        <main>
          <AnimatedHero />
          <About />
          <Projects />
          <Skills />
          <Contact />
        </main>
        <ScrollToTop />
        <Footer />
      </div>
    </Router>
  )
}

export default App
