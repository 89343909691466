import projectImage1 from './img/projectImages/2024.09.05-18.00.27.png'
import projectImage3 from './img/projectImages/logo-Vaniseo.png'


const header = {
  // all the properties are optional - can be left empty or deleted
  homepage: '/',
  title: 'ALF Digital Studio',
}

const about = {
  // all the properties are optional - can be left empty or deleted
  name: 'ALF Digital Studio',
  role: 'développeur web Fullstack',
  description:
    "ALF Digital Studio est une entreprise implantée au cœur du Médoc, une région riche en histoire et en traditions. Bien que notre studio soit basé dans cette magnifique région, nous intervenons également sur Bordeaux et ses environs pour répondre à vos besoins en webdesign et développement web. Que vous soyez dans le Médoc ou à Bordeaux, nous mettons notre expertise à votre service pour réaliser des projets digitaux sur mesure.",
  social: {
    linkedin: 'https://www.linkedin.com/in/fontaine-allan?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
    github: 'https://github.com/FonAl52',
    instagram: 'https://www.instagram.com/alfdigitalstudio/',
  },
}

const projects = [
  // projects can be added an removed
  // if there are no projects, Projects section won't show up
  {
    name: 'Recherches Javascript',
    image:  projectImage1 ,
    description:
      "Ce projet utilise JavaScript et la bibliothèque Canvas-sketch pour générer des effets visuels captivants basés sur l'entrée utilisateur. Avec ce projet, nous montrons comment utiliser des canvas pour créer des animations interactives et visuellement intéressantes, tout en intégrant des éléments interactifs qui réagissent en temps réel à l'entrée clavier.",
    stack: ['Node.js', 'JavaScript', 'Canvas-sketch'],
    sourceCode: 'https://github.com/FonAl52/JS-Research-Canvas-sketch',
    livePreview: 'https://codepen.io/laigAlep/pen/BagGXGd',
  },
  {
    name: 'Développeur Web Vaniseo',
    image:  projectImage3 ,
    description:
      "Découvrez tous les projets sur lesquels j'ai collaboré avec l'équipe de Vaniseo, spécialisés en création de sites web, webdesign créatif, et optimisation SEO.",
    stack: ['Wordpress', 'HTML', 'Css','PHP', 'Javascritp', 'Laravel', 'Symfony'],
    livePreview: 'https://vaniseo.com/agence-web-a-marseille-creation-de-sites-internet-et-publicite/',
  },
]

const skills = [
  // skills can be added or removed
  // if there are no skills, Skills section won't show up
  'HTML',
  'CSS',
  'JavaScript',
  'TypeScript',
  'React',
  'Vue',
  'SASS',
  'Material UI',
  'Git',
  'PHP',
  'Symfony',
  'Laravel',
  'Wordpress'
]

const contact = {
  email: 'contact@alfdigitalstudio.com',
}

export { header, about, projects, skills, contact }
